import { createContext, useContext, useEffect, useState } from "react";
import { ClubSwitchSubject } from "shared/Subjects";
import {
  APIClubsResponseClub,
  TransformedClubRegion,
} from "shared/model/API/api.region.model";
import { getClubsByCurrentUser } from "shared/utils/API/regions.api";

declare global {
  interface Window {
    mox: any;
  }
}
const ClubsContext = createContext<{
  clubs: APIClubsResponseClub[];
  regions: TransformedClubRegion[];
  selectedClub: APIClubsResponseClub | null;
  setSelectedClub: React.Dispatch<
    React.SetStateAction<APIClubsResponseClub | null>
  >;
  setRegionId: React.Dispatch<React.SetStateAction<null>>;
  setHarborId: React.Dispatch<React.SetStateAction<null>>;
  regionId: null;
  harborId: null;
}>({
  clubs: [],
  regions: [],
  selectedClub: null,
  setRegionId: () => {},
  setHarborId: () => {},
  regionId: null,
  harborId: null,
  setSelectedClub: () => {},
});

const ClubsContextProvider = ({ children }: { children: any }) => {
  const [clubs, setClubs] = useState<APIClubsResponseClub[]>([]);
  const [regions, setRegions] = useState<TransformedClubRegion[]>([]);
  const [regionId, setRegionId] = useState(null);
  const [harborId, setHarborId] = useState(null);

  const [selectedClub, setSelectedClub] = useState<APIClubsResponseClub | null>(
    null
  );

  useEffect(() => {
    const reversedClubs = [...clubs].reverse(); // Create a copy and reverse it

    for (const club of reversedClubs) {
      if (club.isClubOnBoarded) {
        setSelectedClub(club);
        break; // Exit loop after finding the first onboarded club
      }
    }
  }, [clubs]);

  useEffect(() => {
    getClubsByCurrentUser().then((clubs) => setClubs(clubs));
  }, []);

  useEffect(() => {
    if (selectedClub) {
      ClubSwitchSubject.next(selectedClub);
    }
  }, [selectedClub]);

  useEffect(() => {
    const regionsTransformed: TransformedClubRegion[] = clubs
      .map((club) =>
        club.regions
          .filter((region) => region?.harbors?.length > 0)
          .map((region) => ({
            regionId: region.regionId,
            regionName: region.regionName,
            clubId: club.clubId,
            harbors: region.harbors.map((harbour) => ({
              clubId: club.clubId,
              name: harbour.harborName,
              id: harbour.harborId,
            })),
          }))
      )
      .flat();
    setRegions(regionsTransformed);
  }, [clubs]);

  return (
    <ClubsContext.Provider
      value={{
        clubs,
        regions,
        selectedClub,
        setSelectedClub,
        setHarborId,
        setRegionId,
        harborId,
        regionId,
      }}
    >
      {children}
    </ClubsContext.Provider>
  );
};

export default ClubsContextProvider;

export const withClubsContext = (Component: any) => (props: any) =>
  (
    <ClubsContextProvider>
      <Component {...props} />
    </ClubsContextProvider>
  );

export const useClubsContext = () => {
  return useContext(ClubsContext);
};
